import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';

import { AppService } from '../../services/app.service';

@Component({
  selector: 'rac-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css']
})
export class RootComponent {

   constructor() {

   }
}
