import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'embolden'
})
export class EmboldenPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // tslint:disable-next-line:prefer-const
    let rpl = RegExp(args, 'i');
    // tslint:disable-next-line:prefer-const
    let result = value.replace(rpl, `<strong>${args}</strong>`);
    return result;
  }

}
