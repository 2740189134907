import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { throwError } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

import { ConfigService } from '../../core/services/config.service';
import { ApiService } from '../../core/services/api.service';

@Injectable()
export class ApplicationService {
    constructor(
        private http: Http,
        private apiService: ApiService,
        private configService: ConfigService
    ) {
    }
    getSettlementDetails(unitCode: string, tillNumber: string) {
      const url = this.configService.config.apiUrl + unitCode + '/' + tillNumber;
      return this.apiService.get(url)
        .pipe(
          map(response => response.body
                          ? (response.body.data ? response.body.data.attributes : 'missing response.body.data')
                          : 'missing response.body'),
          catchError(err => throwError(err))
        );
    }

}
