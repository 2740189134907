import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationMessage } from '../../shared/services/validation-message.service';

@Injectable()
export class CashSettlementValidationService {
  private _messages: ValidationMessage;
  private _validation: FormGroup;

  constructor(formBuilder: FormBuilder) {
    this._messages = {
      unitCode: {
        required: 'Unit Code is required.',
        minlength: 'Unit Code must be 6 characters long',
        maxlength: 'Unit Code must be 6 characters long'
      },
      tillNumber: {
        required: 'Till Number is required.',
        minlength: 'Till Number must be 3 characters long',
        maxlength: 'Till Number must be 3 characters long'
      }
    };

    this._validation = formBuilder.group({
      unitCode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      tillNumber: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]]
    });
  }

  get messages(): ValidationMessage {
    return this._messages;
  }

  get validation(): FormGroup {
    return this._validation;
  }
}
