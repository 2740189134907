import { Component, OnInit, Input } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'rac-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  constructor(public appService: AppService, public configService: ConfigService) { }
}
