import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import { SharedModule } from '../shared/shared.module';

import { ApplicationService } from './services/application.service';
import { CashSettlementValidationService } from './services/cash-settlement-validation.service';
import { BarcodesComponent } from './components/barcodes/barcodes.component';
import { NgxBarcodeModule } from 'ngx-barcode';

import { ApplicationInsightsModule, AppInsightsService } from '@markpieszak/ng-application-insights';

const childRoutes = [
  {
    path: 'barcodes',
    component: BarcodesComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    NgxBarcodeModule,
    ApplicationInsightsModule.forRoot({
      instrumentationKeySetlater: true
    }),
    RouterModule.forChild(childRoutes)
  ],
  declarations: [BarcodesComponent],
  providers: [
    ApplicationService,
    CashSettlementValidationService,
    AppInsightsService
  ]
})
export class RacFormsModule { }
