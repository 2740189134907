import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'rac-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private appService: AppService, private router: Router) {
    appService.pageName = 'Home';
  }

  ngOnInit() {
    this.router.navigate(['/barcodes']);
  }

}
