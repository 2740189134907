import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EmboldenPipe } from './pipe/embolden.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    EmboldenPipe
  ],
  providers: [
    ],
  exports: [
    ]
})
export class SharedModule { }
