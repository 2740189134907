import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import {  HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Routes, RouterModule } from '@angular/router';

import { RacFormsModule } from '../forms/rac-forms.module';

import { ErrorComponent } from './components/error/error.component';
import { RootComponent } from './components/root/root.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavComponent } from './components/nav/nav.component';
import { HomeComponent } from './components/home/home.component';
import { ConfigService } from './services/config.service';
import { AppService } from './services/app.service';
import { ApiService } from './services/api.service';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: []
  },
  {
    path: '**',
    component: ErrorComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RacFormsModule,
    RouterModule.forRoot(routes),
    // Ensure RouterModule is last for child routing
  ],
  declarations: [ErrorComponent, RootComponent, HeaderComponent, FooterComponent, NavComponent, HomeComponent],
  exports: [CommonModule, BrowserModule, BrowserAnimationsModule, ErrorComponent, RootComponent, HeaderComponent, FooterComponent],
  providers: [
    HttpClient,
    ApiService,
    AppService,
    ConfigService,
    {
      provide: APP_INITIALIZER,
      deps: [ConfigService],
      multi: true,
      useFactory: (config: ConfigService) => () => config.load(),
    }
  ]
})
export class CoreModule { }
