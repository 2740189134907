import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import {map, tap } from 'rxjs/operators';

import { IConfig } from '../interfaces/iconfig';

@Injectable()
export class ConfigService {
  private _config: IConfig = <IConfig>{};

  get config() {
    return this._config;
  }

  constructor(private http: Http) {
  }

  load() {
    return this.http.get('app/configuration/app-settings.json')
      .pipe(
        map(res => res.json()),
        tap((config) => this._config = config)
      ).toPromise();
  }
}



