import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ReactiveFormBaseService } from '../../../shared/services/reactive-form-base.service';
import { CashSettlementValidationService } from '../../services/cash-settlement-validation.service';
import { ApplicationService } from '../../services/application.service';
import { AppInsightsService } from '@markpieszak/ng-application-insights';
import { ConfigService } from '../../../core/services/config.service';
@Component({
  selector: 'rac-barcodes',
  templateUrl: './barcodes.component.html',
  styleUrls: ['./barcodes.component.css']
})
export class BarcodesComponent extends ReactiveFormBaseService implements OnInit {
  patrolUnitBarcode = '';
  cashAmountBarcode = '';
  depositTotal = 0.00;
  settledDateTime = new Date();
  unitEditMode = false;
  tillEditMode = false;
  isLoadComplete = false;
  errorMessage = '';
  isRefreshing = false;
  storedUnitCode = '';
  storedTillNumber = '';

  @ViewChild('unitCode') unitCodeElement: ElementRef;
  @ViewChild('tillNumber') tillNumberElement: ElementRef;

  constructor(private appService: ApplicationService,
              private validationService: CashSettlementValidationService,
              private configService: ConfigService,
              private appInsightsService: AppInsightsService) {
    super(validationService.validation, <any>validationService.messages);
    appInsightsService.config = {
      instrumentationKey: configService.config.appInsights.instrumentationKey // <-- set it later sometime
    };
    // then make sure to initialize and start-up app insights
    appInsightsService.init();
  }

  editUnitCode() {
    this.unitEditMode = true;
    const unitCodeElement = <HTMLInputElement>this.unitCodeElement.nativeElement;
    unitCodeElement.disabled = false;
    unitCodeElement.select();
    return;
  }
  saveUnitCode() {
    this.unitEditMode = false;
    localStorage.setItem('unitCode', this.formGroup.get('unitCode').value);
    return;
  }

  editTillNumber() {
    this.tillEditMode = true;
    const tillNumberElement = <HTMLInputElement>this.tillNumberElement.nativeElement;
    tillNumberElement.disabled = false;
    tillNumberElement.select();
    return;
  }
  saveTillNumber() {
    this.tillEditMode = false;
    localStorage.setItem('tillNumber', this.formGroup.get('tillNumber').value);
    return;
  }
  ngOnInit() {
    this.getData();
  }

  getData() {
    this.isRefreshing = true;
    const defaultUnit = '6VU000';
    const defaultTill = '000';
    const unitBarcodePrefix = '*36130000000';
    this.storedUnitCode = localStorage.getItem('unitCode') || defaultUnit;
    this.storedTillNumber = localStorage.getItem('tillNumber') || defaultTill;
    this.patrolUnitBarcode = unitBarcodePrefix + this.storedUnitCode.slice(-3);

    this.formGroup.get('unitCode').setValue(this.storedUnitCode);
    this.formGroup.get('tillNumber').setValue(this.storedTillNumber);

    this.appService
    .getSettlementDetails(this.storedUnitCode, this.storedTillNumber)
    .subscribe(
        response => {
          this.errorMessage = '';
          this.depositTotal = response.totalEntered;
          this.settledDateTime = response.dateTimeLodged;
          this.cashAmountBarcode = response.receiptBarcode;
          this.isLoadComplete = true;
          this.isRefreshing = false;
        },
        err => {
          this.isLoadComplete = true;
          this.isRefreshing = false;
          const errObject = err ? JSON.parse(err) : null;
          if (errObject && (errObject.status === 400 || errObject.status === 404)) {
            // tslint:disable-next-line:max-line-length
            this.errorMessage = `Unable to fetch record for unit: ${this.storedUnitCode} with till number: ${this.storedTillNumber}, please check Configuration section below.`;
            return;
          }
          this.errorMessage = 'Server error encountered, please contact RAC Group IT if problem persists.';
        }
      );
  }
}
