import { Injectable } from '@angular/core';
import { iuser } from '../interfaces/iuser';

@Injectable()
export class AppService {

  pageName: string;
  currentUser: iuser;

  constructor() { }

}
