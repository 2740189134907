import { ElementRef, ViewChildren, AfterViewInit } from '@angular/core';
import { FormGroup, FormControlName } from '@angular/forms';
import { Observable, fromEvent, merge } from 'rxjs';
import { debounceTime  } from 'rxjs/operators';

import { ISavable } from '../interfaces/isavable';
import { ValidationMessage } from './validation-message.service';

export class ReactiveFormBaseService implements AfterViewInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

  public displayMessage: { [key: string]: string } = {};
  protected child: ISavable;
  protected onValidSaveAsync: () => Observable<{}>;

  constructor(
    public formGroup: FormGroup,
    protected validationMessages: ValidationMessage) {
  }

  ngAfterViewInit(): void {

    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

      merge(this.formGroup.valueChanges, ...controlBlurs).pipe(debounceTime(800)).subscribe(value => {
      this.displayMessage = this.processMessages(this.formGroup);
    });
  }

  public onSave(): void {
    if (this.formGroup.valid) {

      if (this.onValidSaveAsync) {
        this.onValidSaveAsync().subscribe({
          error: err => console.log(`${err}`),
          complete: () => this.onSaveComplete(),
        });
      } else if (this.child.onValidSave()) {
        this.onSaveComplete();
      }
    } else {
      this.child.onInvalidSave();
      this.displayErrorMessages();
    }
  }

  protected onSaveComplete(): void {
  }

  protected displayErrorMessages(): void {

    this.displayMessage = this.processMessages(this.formGroup);
  }

  protected processMessages(container: FormGroup): { [key: string]: string } {
    const messages = {};

    for (const controlKey in container.controls) {
      if (container.controls.hasOwnProperty(controlKey)) {
        const c = container.controls[controlKey];

        if (c instanceof FormGroup) {
          const childMessages = this.processMessages(c);
          Object.assign(messages, childMessages);
        } else {

          if (this.validationMessages[controlKey]) {
            messages[controlKey] = '';
            if (c.errors) {
              Object.keys(c.errors).map(messageKey => {
                if (this.validationMessages[controlKey][messageKey]) {
                  messages[controlKey] += this.validationMessages[controlKey][messageKey] + ' ';
                }
              });
            }
          }
        }
      }
    }

    return messages;
  }

  protected getErrorCount(container: FormGroup): number {
    let errorCount = 0;

    for (const controlKey in container.controls) {
      if (container.controls.hasOwnProperty(controlKey)) {
        if (container.controls[controlKey].errors) {
          errorCount += Object.keys(container.controls[controlKey].errors).length;
          console.log(errorCount);
        }
      }
    }

    return errorCount;
  }
}
