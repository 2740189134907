import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { AppInsightsService } from '@markpieszak/ng-application-insights';

@Injectable()
export class ApiService {

  constructor(private http: HttpClient,
    private appConfig: ConfigService,
    private appInsightsService: AppInsightsService) {}

  public get(apiUrl: string, id: number = 0): Observable<any> {
    this.appInsightsService.trackEvent('Initiating get data', { 'apiUrl': apiUrl });
    const options: any = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Ocp-Apim-Subscription-Key', this.appConfig.config.apimSubscriptionKey)
      , observe: 'response'
    };
    return this.http
          .get(`${apiUrl}`, options)
          .pipe(
            tap(result => this.appInsightsService.trackEvent('Got response from ' + apiUrl)),
            catchError(err => throwError(JSON.stringify(err)))
          );
  }

  public post(apiUrl: string, id: number, body: any): Observable<any> {
    return this.http.post(apiUrl, body);
  }

  private handleError(err: HttpErrorResponse) {
    // this.telemetryService.logTelemetry('error', 'error encountered', err, '');

    let errorMessage = '';
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
  }
}
